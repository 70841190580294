<template>
  <div
    v-if="loginType == 0 || loginType == 1 || loginType == 2"
    :class="{
      contain: loginType == 0 || loginType == 1,
      'contain-feixun': loginType == 2,
    }"
  >
    <div
      class="left"
      :style="{ backgroundImage: 'url(' + loginImg + ')' }"
    ></div>
    <div class="right">
      <div class="main">
        <img v-if="logo" class="login-logo" :src="logo" alt="LOGO" />
        <h1 v-else class="logo-str">LOGO</h1>
        <!-- 账号登录 -->
        <div v-show="isAccountLogin">
          <a-form layout="vertical" :model="loginParams">
            <!-- 账号 -->
            <a-form-item :label="$t('login.account')">
              <a-input
                v-model:value.trim="loginParams.account"
                :placeholder="$t('login.account_p')"
                size="large"
                @pressEnter="fnLogin"
                @blur="checkPass"
              >
                <!-- 请输入您的手机号/邮箱/账号 -->
                <template #addonBefore>
                  <UserOutlined style="font-size: 24px; color: #9e9e9e" />
                </template>
              </a-input>
            </a-form-item>
            <!-- 密码 -->
            <a-form-item :label="$t('login.password')">
              <a-input-password
                v-model:value.trim="loginParams.password"
                :placeholder="$t('login.password_p')"
                size="large"
                @pressEnter="fnLogin"
              >
                <!-- 请输入您的密码 -->
                <template #addonBefore>
                  <LockOutlined style="font-size: 24px; color: #9e9e9e" />
                </template>
              </a-input-password>
            </a-form-item>
            <!-- 验证码 -->
            <a-form-item :label="$t('login.captcha')" v-if="imgCaptchaShow">
              <a-input
                v-model:value.trim="loginParams.captcha"
                :placeholder="$t('login.captcha_p')"
                size="large"
                @pressEnter="fnLogin"
                :maxlength="10"
              >
                <!-- 请输入验证码 -->
                <template #addonBefore>
                  <SafetyCertificateOutlined
                    style="font-size: 24px; color: #9e9e9e"
                  />
                </template>
              </a-input>
              <img
                class="captcha-img"
                :src="captchaObj.img"
                alt="code"
                @click="getImgCaptcha"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                size="large"
                @click="fnLogin"
                :loading="loading"
                >{{ $t("CM_SignIn") }}</a-button
              >
              <!-- 登录 -->
            </a-form-item>
            <div class="footer">
              <template v-if="useRegister == 1">
                <router-link to="/register">{{
                  $t("login.register")
                }}</router-link>
                <!-- 注册账号 -->
              </template>
              <router-link to="/forgot">{{ $t("login.forgot") }}</router-link>
              <!-- 忘记密码 -->
            </div>
          </a-form>
          <a-divider v-if="[30, 20].includes(config.platform) && !ISHTSY">
            <div class="toggle-login" @click="isAccountLogin = false">
              {{ $t("login.scan_code_login") }}
              <!-- 扫码登录 -->
              <DingtalkCircleFilled v-if="config.platform == 30" />
              <WechatFilled v-else />
            </div>
          </a-divider>
        </div>
        <!-- 扫码登录 -->
        <div class="qr-login" v-if="!isAccountLogin">
          <div class="login-type" id="ding-login-container">
            <dingqr />
          </div>
          <a-divider>
            <div class="toggle-login" @click="isAccountLogin = true">
              {{ $t("login.account_login") }}
              <!-- 账号登录 -->
              <DesktopOutlined />
            </div>
          </a-divider>
        </div>
      </div>
    </div>
  </div>
  <!-- 自定义登陆 -->
  <loginTpl v-if="loginType == 3 || loginType == 4" />
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  defineComponent,
  defineAsyncComponent,
  computed,
} from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { login, accountCheck, dingPCLogin } from "@/api/user";
import { getCaptcha, platformConfig } from "@/api/other";
import {
  appendJs,
  clearStorage,
  loadCSS,
  loadJs,
  getQueryVariable,
} from "@/utils/tools.js";
import { currentHost } from "@/utils/request.js";
import { successCallback } from "@/utils/login.js";
import dingqr from "@/views/login/dingqr.vue";

const reactive2 = reactive;
const toRefs2 = toRefs;
const getCurrentInstance2 = getCurrentInstance;
const watch2 = watch;
const onMounted2 = onMounted;
const defineComponent2 = defineComponent;
const useRoute2 = useRoute;
const useStore2 = useStore;
const login2 = login;
const accountCheck2 = accountCheck;
const getCaptcha2 = getCaptcha;
const appendJs2 = appendJs;
const clearStorage2 = clearStorage;
const currentHost2 = currentHost;
const successCallback2 = successCallback;
const loadCSS2 = loadCSS;
const loginTpl = defineAsyncComponent(() => {
  return new Promise((resolve, reject) => {
    (async function () {
      try {
        let res = await platformConfig({ site: currentHost });
        let loginImg = res.data.loginImg || "";
        let loginCom = eval("(" + loginImg + ")");
        resolve(loginCom);
      } catch (error) {
        reject(error);
      }
    })();
  });
});

export default {
  components: {
    loginTpl,
    dingqr,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      redirect: decodeURIComponent(route.query.redirect || "/"),
      loginParams: {
        captcha: "",
        account:
          process.env.NODE_ENV === "production" ||
          process.env.NODE_ENV === "low"
            ? ""
            : "admin",
        password:
          process.env.NODE_ENV === "production" ||
          process.env.NODE_ENV === "low"
            ? ""
            : "admin123@",
      },
      isAccountLogin: true,
      loading: false,
      imgCaptchaShow: false,
      captchaObj: {
        id: "",
        img: "",
      },
      logo: "",
      useRegister: 2,
      studyCopyright: "",
      icpNo: "",
      ncNo: "",
      loginType: 0,
      loginImg: "",
      defalitLoginImg: require("@/assets/image/login1920.png"),
      defalitLoginImg2: require("@/assets/image/login-img2.jpg"),
    });

    const langNamesEnum = {
      zh_CN: "zh-CN",
      zh_TW: "zh-TW",
      en_US: "en-US",
    };

    onMounted(() => {
      watch(
        () => store.getters.platformConfig,
        (val) => {
          if (val.CustomerID) {
            state.useRegister = val.useRegister;
            state.logo = val.logo1;
            state.loginImg = val.loginImg
              ? val.loginImg
              : val.loginType == 2
              ? state.defalitLoginImg2
              : state.defalitLoginImg;
            state.studyCopyright = val.studyCopyright;
            state.icpNo = val.icpNo;
            state.ncNo = val.ncNo;
            state.loginType = val.loginType;
            appendJs("Track", val.studyTrack);
          }
        },
        { immediate: true, deep: true }
      );
      // ddLogin();
    });

    clearStorage();

    const checkPass = () => {
      accountCheck({
        domain: currentHost,
        account: proxy.$getRsaCode(state.loginParams.account),
      }).then((res) => {
        if (
          res.data &&
          res.data.logins >= 3 &&
          res.data.logins <= res.data.max
        ) {
          getImgCaptcha();
          state.imgCaptchaShow = true;
        }
      });
    };

    const fnLogin = async () => {
      if (state.loginParams.account == "") {
        return proxy.$message.error($t("login.account_p2"));
        // 请输入账号！
      } else if (state.loginParams.password == "") {
        return proxy.$message.error($t("login.password_p"));
        // 请输入密码！
      } else if (state.imgCaptchaShow && state.loginParams.captcha == "") {
        return proxy.$message.error($t("login.captcha_p"));
        // 请输入验证码！
      }

      state.loading = true;
      let formData = {
        domain: currentHost,
        platform: 1,
        account: proxy.$getRsaCode(state.loginParams.account),
        password: proxy.$getRsaCode(state.loginParams.password),
      };
      if (state.imgCaptchaShow) {
        formData.captcha = state.loginParams.captcha;
        formData.captchaKey = state.captchaObj.id;
      }
      let res = await login(formData);
      state.loading = false;
      if (res.ret === 0) {
        successCallback(res, state);
        store.dispatch("setPlatform", "web");
      } else {
        if (
          res.data &&
          res.data.logins >= 3 &&
          res.data.logins < res.data.max
        ) {
          getImgCaptcha();
          state.imgCaptchaShow = true;
        }
      }
    };

    const getImgCaptcha = () => {
      getCaptcha().then((res) => {
        state.captchaObj = res.data;
      });
    };
    getImgCaptcha();

    /**
     * 扫码登录
     * platform = 30 开启了钉钉扫码登录 页面显示
     * platform = 20 开启了微信服务号扫码登录 页面显示
     * htsy 三院 不能引入外部js 屏蔽
     */
    const config = computed(() => {
      return store.getters.platformConfig;
    });
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const ddConfig = {
      appId: config.value.appId || 0,
      redirect_url: `${location.protocol}//${config.value.studyDomain}/login`,
    };
    const ddLogin = () => {
      if (config.value.platform !== 30 || ISHTSY) return;
      const code = getQueryVariable("code"); // 查看当前url是否有code参数 有就说明扫码登录重定向成功
      if (code) {
        dingLogin(code);
      } else {
        initDing();
      }
    };
    const initDing = () => {
      loadJs("https://g.alicdn.com/dingding/dinglogin/0.0.5/ddLogin.js").then(
        () => {
          var obj = DDLogin({
            id: "ding-login-container",
            goto: encodeURIComponent(
              `https://oapi.dingtalk.com/connect/qrconnect?appid=${ddConfig.appId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${ddConfig.redirect_url}`
            ),
            style: "border:none;background-color:#FFFFFF;",
            width: "276",
            height: "350",
          });
          if (typeof window.addEventListener != "undefined") {
            window.addEventListener("message", handleMessage, false);
          } else if (typeof window.attachEvent != "undefined") {
            window.attachEvent("onmessage", handleMessage);
          }
        }
      );
    };
    const handleMessage = (event) => {
      var origin = event.origin;
      if (origin == "https://login.dingtalk.com") {
        //判断是否来自ddLogin扫码事件。
        var loginTmpCode = event.data;
        //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        if (loginTmpCode) {
          window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${ddConfig.appId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${ddConfig.redirect_url}&loginTmpCode=${loginTmpCode}`;
        }
      }
    };
    const dingLogin = (code) => {
      const param = {
        appid: config.value.appId || 0,
        // code: proxy.$getRsaCode(code),
        code: code,
        site: currentHost,
      };
      dingPCLogin(param).then((res) => {
        if (res.ret === 0) {
          successCallback(res, state);
          store.dispatch("setPlatform", "web");
        }
      });
    };
    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      fnLogin,
      getImgCaptcha,
      checkPass,
      config,
      ISHTSY,
    };
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  overflow-y: hidden;
  .left {
    width: 40%;
    height: 100%;
    float: left;
    position: relative;
    min-height: 600px;
    // background-image: url(../assets/image/login1920.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .right {
    width: 60%;
    height: 100%;
    float: right;
    min-height: 600px;
    position: relative;
  }
  .main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 576px;
    width: 576px;
    // margin: 15% auto;
    .login-logo {
      max-width: 368px;
      height: 67px;
      margin-bottom: 60px;
    }
    .logo-str {
      width: 368px;
      height: 67px;
      margin-bottom: 60px;
      line-height: 67px;
      font-size: 36px;
      letter-spacing: 4px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 2px 0;
    margin-bottom: 40px;
    font-size: 16px;
    color: #a6b0bf;
    a {
      font-size: 16px;
      color: #a6b0bf;
    }
    .scanCode {
      cursor: pointer;
    }
  }
  .bottom {
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
    font-size: 12px;
    color: #b0bbc3;
    ::v-deep(a) {
      color: #b0bbc3 !important;
    }
  }
  .ant-form-item {
    margin-bottom: 40px;
    ::v-deep(.ant-form-item-label > label) {
      font-size: 16px;
      color: #202020;
    }
    ::v-deep(.ant-form-item-label) {
      padding: 0 0 12px;
    }
    ::v-deep(.ant-input-group-addon) {
      padding: 0 22px;
      position: absolute;
      left: 1px;
      top: 1px;
      z-index: 99;
      height: 97%;
      line-height: 66px;
      border: none;
      background: none;
    }
    ::v-deep(.ant-input-lg) {
      padding: 20.5px 11px 20.5px 66px;
      border-radius: 4px;
    }
    ::v-deep(.ant-input-affix-wrapper-lg) {
      padding: 0 11px 0 1px;
      border-radius: 4px;
    }
    ::v-deep(.ant-btn-lg) {
      width: 300px;
      height: 68px;
      padding: 6.4px 75px;
      border-radius: 4px;
      background: @color-theme;
      border-color: @color-theme;
      font-size: 20px;
      box-shadow: 0px 7px 10px 0px rgba(59, 109, 205, 0.2);
    }
  }
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 75px;
  }
  .login-type {
    font-size: 20px;
    color: #202020;
    span {
      cursor: pointer;
    }
  }
  .qrCode {
    width: 246px;
    height: 246px;
    margin: 29px 0 60px;
    box-shadow: 22px 22px 10px #f4f7fc;
  }
  p {
    font-size: 16px;
    color: #a6b0bf;
    margin-bottom: 22px;
  }
  .login-more {
    font-size: 16px;
    color: #202020;
    .wx,
    .dd {
      margin-right: 51px;
      cursor: pointer;
    }
    .anticon {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  .captcha-img {
    position: absolute;
    right: 0;
    top: 1px;
    width: 220px;
    height: 66px;
    cursor: pointer;
    z-index: 100;
  }
  .footer-feixun {
    display: none;
  }
}
.contain-feixun {
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  overflow-y: hidden;
  position: relative;
  .left {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .right {
    width: 440px;
    min-height: 516px;
    position: absolute;
    top: calc(50% - 270px);
    right: calc(25% - 220px);
    background-color: #fff;
    .main {
      width: 100%;
      height: 100%;
      padding: 40px 60px 23px;
      .login-logo {
        display: block;
        max-width: 350px;
        height: 70px;
        margin: 0 auto 50px;
      }
      .logo-str {
        width: 350px;
        height: 70px;
        margin-bottom: 50px;
        line-height: 70px;
        font-size: 70px;
        letter-spacing: 4px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
      }
      .ant-form-item {
        margin-bottom: 17px;
      }
      ::v-deep(.ant-form-item-label) {
        display: none;
      }
      ::v-deep(.ant-input-group-addon) {
        display: none;
      }
      ::v-deep(.ant-input) {
        //background-color: #f4f4f4;
        line-height: 46px;
      }
      ::v-deep(.ant-input-password) {
        //background-color: #f4f4f4;
      }
      .captcha-img {
        position: absolute;
        right: 0;
        top: 1px;
        width: 200px;
        height: 60px;
        cursor: pointer;
        z-index: 100;
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a0a5ab;
        a {
          color: #a0a5ab;
        }
      }
      .ant-btn.ant-btn-primary.ant-btn-lg {
        width: 100%;
        line-height: 41px;
        height: 54px;
        background: @color-theme;
        border-color: @color-theme;
        margin-top: 10px;
        border-radius: 2px;
        font-size: 18px;
      }
    }
    .footer,
    // .qr-login,
    .bottom {
      display: none;
    }
  }
}
.toggle-login {
  cursor: pointer;
  span {
    color: var(--theme);
  }
}
.qr-login {
  .login-type {
    width: 300px;
    margin: 0 auto;
  }
}
</style>
